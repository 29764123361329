import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import Team from '@/domain/entity/Team';
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import * as agh from "agh.sprintf";
import Constant from "@/commons/Constant";
import Input from "@/presentation/components/input/Input.vue";
import { cloneDeep } from 'lodash';
import { useStore } from "vuex";
import draggable from "vuedraggable";
import MenuIcon from 'vue-material-design-icons/Menu.vue';
export default defineComponent({
    name: "TeamInfoEdit",
    components: {
        ErrorMessage: ErrorMessage,
        Textarea: Textarea,
        Input: Input,
        draggable: draggable,
        MenuIcon: MenuIcon
    },
    props: [
        "field",
        "info",
        "source",
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        onMounted(function () {
            setValues({ info: props.info });
        });
        var _b = useForm({
            initialValues: props.info
        }), handleSubmit = _b.handleSubmit, values = _b.values, setValues = _b.setValues, errors = _b.errors, isSubmitting = _b.isSubmitting;
        var store = useStore();
        var state = reactive({
            keyword: "",
            selected_project_name: [],
            selectedProjects: props.info.selected_project_request || [],
            reason: "",
            tempReasons: (props.info && props.info.selected_project_request) ? props.info.selected_project_request.map(function (request) { return request.reason || ''; }) : [],
            aLang: LanguageUtil.getInstance().lang,
            field: props.field,
            errorUpdateTeamInfoMessage: "",
            errorValidate: {},
            temporarySelectedProjects: props.info.selected_project_request || [],
        });
        var onChangeKeyword = function (event) {
            state.keyword = event.target.value;
        };
        var filteredListProject = computed(function () {
            if (!state.keyword) {
                var initialProjects = props.info.project_name.map(function (name, index) { return ({
                    name: name,
                    organization_id: props.info.organization_id[index],
                    organization_name: props.info.organization_name[index],
                    project_categories: props.info.project_categories[index],
                    id: props.info.project_id[index],
                    reason: state.reason[index] ? state.reason[index] : "",
                    checked: false,
                    index: index
                }); });
                var selectedProjectIds_1 = state.selectedProjects.map(function (project) { return project.id; });
                return initialProjects.filter(function (project) { return !selectedProjectIds_1.includes(project.id); });
            }
            var keyword = state.keyword;
            return props.info.project_name.reduce(function (filteredProjects, name, index) {
                if (name.includes(keyword) || props.info.organization_name[index].includes(keyword) || props.info.project_categories[index].includes(keyword)) {
                    filteredProjects.push({
                        name: name,
                        organization_id: props.info.organization_id[index],
                        organization_name: props.info.organization_name[index],
                        project_categories: props.info.project_categories[index],
                        id: props.info.project_id[index],
                        reason: state.reason[index] ? state.reason[index] : "",
                        checked: false,
                        index: index
                    });
                }
                var selectedProjectIds = state.selectedProjects.map(function (project) { return project.id; });
                return filteredProjects.filter(function (project) { return !selectedProjectIds.includes(project.id); });
            }, []);
        });
        var selectedProjectIds = reactive([]);
        var onSelectProject = function (id) {
            var idx = selectedProjectIds.indexOf(id);
            if (idx === -1) {
                selectedProjectIds.push(id);
            }
            else {
                selectedProjectIds.splice(idx, 1);
            }
        };
        var isSelectAll = ref(false);
        var addProjectsToSelected = function () {
            var newSelectedProjects = state.selectedProjects.slice();
            selectedProjectIds.forEach(function (id) {
                var project = filteredListProject.value.find(function (project) { return project.id === id; });
                if (project && !newSelectedProjects.includes(project)) {
                    newSelectedProjects.push(project);
                }
            });
            state.selectedProjects = newSelectedProjects;
            selectedProjectIds.splice(0, selectedProjectIds.length);
            isSelectAll.value = false;
        };
        var toggleSelectAll = function () {
            isSelectAll.value = !isSelectAll.value;
            if (isSelectAll.value) {
                selectedProjectIds = filteredListProject.value.map(function (project) { return project.id; });
            }
            else {
                selectedProjectIds.splice(0, selectedProjectIds.length);
            }
        };
        var removeSelectedProject = function (id) {
            var idx = state.selectedProjects.findIndex(function (project) { return project.id === id; });
            if (idx !== -1) {
                var selectedProjects = state.selectedProjects.slice();
                var removedProject_1 = selectedProjects.splice(idx, 1)[0];
                state.selectedProjects = selectedProjects;
                if (!filteredListProject.value.find(function (project) { return project.id === removedProject_1.id; })) {
                    filteredListProject.value.push(removedProject_1);
                }
            }
            selectedProjectIds.splice(0, selectedProjectIds.length);
            isSelectAll.value = false;
        };
        var updateSelectedProjectRequest = function () {
            props.info.selected_project_request = state.selectedProjects;
        };
        var updateSelectedProjectRequestReason = function () {
            props.info.selected_project_request.forEach(function (project, i) {
                project.reason = state.tempReasons[i];
            });
        };
        // category array
        var projectCategory = [
            { id: 1000, name: 'デジタル化の可能性検討', order: 1, notice: '(DXへの意識醸成支援やDXの推進計画の策定)' },
            { id: 1001, name: 'データ分析を通じたデータ・デジタル技術の活用可能性の設計/初期的な検証', order: 2, notice: '(DX施策の具体化、効果の検証)' },
            { id: 1002, name: 'データ・デジタル技術を用いた新規事業検討', order: 3, notice: '(新たなビジネスや組織変革の計画検討)' },
        ];
        var categoryData = reactive({
            data: []
        });
        var detectChecked = function (target) {
            var _a;
            return (_a = values[props.field]) === null || _a === void 0 ? void 0 : _a.find(function (row) { return row.id === target.id; });
        };
        var setChecked = function (target) {
            var _a;
            var newValues = cloneDeep(values[props.field]) || [];
            if (detectChecked(target)) {
                newValues = newValues.filter(function (row) { return row.id !== target.id; });
            }
            else {
                newValues.push(target);
            }
            setValues((_a = {}, _a[props.field] = newValues, _a));
        };
        var onSort = function () {
            var _a;
            var orderArray = [];
            categoryData.data.map(function (data, i) {
                var category = { id: data.id, name: data.name, order: i + 1, notice: data.notice };
                orderArray.push(category);
            });
            categoryData.data = orderArray;
            setValues((_a = {}, _a[props.field] = categoryData.data, _a));
        };
        // prefectures
        var prefectures = computed(function () {
            return store.state.prefectures;
        });
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        // validate
        var id = useField("id", yup
            .number()).value;
        var name = useField("name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(50, agh.sprintf(state.aLang.validation_max_length, 50))).value;
        var name_kana = useField("name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(50, agh.sprintf(state.aLang.validation_max_length, 50))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)).value;
        var description = useField("description", yup
            .string()
            .required(state.aLang.validation_required)
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))).value;
        var project_categories = useField("project_categories", yup
            .array()).value;
        var available_datetimes = useField("available_datetimes", yup
            .array()).value;
        var validateProjects = function () {
            if (state.selectedProjects.length === 0) {
                return false;
            }
            return true;
        };
        var validateAvailableDatetimes = function () {
            if (!values.available_datetimes || !values.available_datetimes.length) {
                return false;
            }
            return true;
        };
        var onSubmit = handleSubmit(function () {
            if (props.field === 'request_project' && !validateProjects()) {
                state.errorUpdateTeamInfoMessage = state.aLang.validation_required;
                return;
            }
            if (props.field === 'available_datetimes' && !validateAvailableDatetimes()) {
                state.errorUpdateTeamInfoMessage = state.aLang.validation_required;
                return;
            }
            var params = new Team();
            values.team_id = values.id;
            delete values.id;
            Object.assign(params, values);
            for (var key in params) {
                params[key] = params[key] ? params[key] : null;
            }
            return teamInteractor.updateTeam(params).then(function (result) {
                if (props.field === 'project_categories') {
                    emit("updateValue", {
                        field: props.field,
                        value: values[props.field]
                    });
                    emit("updateValue", {
                        field: 'refusal_project_category_id',
                        value: values.refusal_project_category_id
                    });
                    emit("updateValue", {
                        field: 'ng_phase_reason',
                        value: values.ng_phase_reason
                    });
                }
                else {
                    emit("updateValue", {
                        field: props.field,
                        value: values[props.field]
                    });
                }
                ;
                emit("close");
            }).catch(function (error) {
                if (error.validate_errors) {
                    state.errorValidate = error.validate_errors;
                }
                if (error.message) {
                    state.errorUpdateTeamInfoMessage = error.message;
                }
            });
        });
        return {
            state: state,
            errors: errors,
            values: values,
            onSubmit: onSubmit,
            isSubmitting: isSubmitting,
            detectChecked: detectChecked,
            setChecked: setChecked,
            prefectures: prefectures,
            categoryData: categoryData,
            onSort: onSort,
            onChangeKeyword: onChangeKeyword,
            updateSelectedProjectRequest: updateSelectedProjectRequest,
            filteredListProject: filteredListProject,
            onSelectProject: onSelectProject,
            addProjectsToSelected: addProjectsToSelected,
            selectedProjectIds: selectedProjectIds,
            removeSelectedProject: removeSelectedProject,
            toggleSelectAll: toggleSelectAll,
            isSelectAll: isSelectAll,
            updateSelectedProjectRequestReason: updateSelectedProjectRequestReason
        };
    }
});
