<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot v-if="!slackInOperation" name="body">
              <div class="applicant_project_completed__container">
                <div class="applicant_project_completed__container-title">
                  Slackチャンネル作成の結果
                </div>
                <p v-if="slackResult" class="applicant_project_completed__container-subtitle">
                  Slackチャンネルは正常に作成されました。<br>
                  チャンネル名 :「{{ slackCreateChannelName }}」<br>
                  <span class="slack_team_channel_success_notice">※今後のメンバー追加に関しましては、Slack上で直接招待をお願いいたします。</span>
                </p>
                <p v-else class="applicant_project_completed__container-subtitle">
                  Slackチャンネルの作成に失敗しました。<br>
                  <span class="slack_team_channel_failure_notice">※お手数ですが、運営事務局にご連絡をお願いいたします。</span>
                </p>
              </div>
              <button class="btn-light-gray btn-md" @click="closeSlackAlert()">閉じる</button>
            </slot>

            <slot v-if="slackInOperation" name="body">
              <div class="applicant_project_completed__container">
                <div class="applicant_project_completed__container-title">
                  処理中
                </div>
                <p class="applicant_project_completed__container-subtitle">
                  現在、マッチングとSlackチャンネルを作成しております。<br>
                  操作はしないでそのままでお待ち下さい<br>
                 <span class="slack_team_channel_in_operation_notice">※お時間がかかる場合がございます。</span><br>
                </p>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/slack-alert/SlackAlert.ts" />
<style lang="scss" scoped src="@/presentation/components/modals/slack-alert/SlackAlert.scss" />