import { defineComponent } from "vue";
export default defineComponent({
    name: "ConfirmCompletePopup",
    props: {
        isSubmitting: Boolean,
        title: {
            type: String,
            default: "確認",
        },
        description: {
            type: String,
            default: null,
            required: false,
        },
        okText: {
            type: String,
            default: "決定",
        },
        cancelText: {
            type: String,
            default: "キャンセル",
        },
    },
    emits: ["confirmed", "canceled"],
    setup: function (props) {
        return {
            props: props,
        };
    }
});
