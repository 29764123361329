import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive } from "vue";
import { useForm } from "vee-validate";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import Input from "@/presentation/components/input/Input.vue";
export default defineComponent({
    name: "TeamInfoEdit",
    components: {
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    props: [
        "isOwner",
        "info",
        "team_id"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            showDropdown: false,
            errorInviteMemberMessage: "",
            isSubmitting: false,
            team_id: props.team_id
        });
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        var _b = useForm(), values = _b.values, isSubmitting = _b.isSubmitting, handleSubmit = _b.handleSubmit, errors = _b.errors;
        var changeLeaderRequest = handleSubmit(function () {
            return teamInteractor.cancelChangeLeader(state.team_id).then(function (result) {
                emit("close");
                emit("success");
            }).catch(function (error) {
                if (error.message) {
                    state.errorInviteMemberMessage = error.message;
                }
            });
        });
        function checkErrorMessage(error) {
            if (error.email) {
                state.errorInviteMemberMessage = "";
                return true;
            }
            return false;
        }
        return {
            state: state,
            isSubmitting: isSubmitting,
            values: values,
            errors: errors,
            changeLeaderRequest: changeLeaderRequest,
            checkErrorMessage: checkErrorMessage
        };
    }
});
