<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="team_info__edit__container">
                <div class="team_info__edit__container-title">リーダーを変更する</div>
                <p class="change-leader-request">変更依頼を依頼するメンバーを選択し、「依頼する」をクリックしてください</p>
                <form class="horizontal" @submit="changeLeaderRequest">
                    <div class="form-ctrl">
                        <div class="form-group">
                            <select
                                v-model="values.new_leader_account_id"
                                :class="{ invalid: !values.new_leader_account_id }"
                                class="select"
                            >
                                <option value='' disabled selected style='display:none;'>お選びください</option>
                                <option
                                    v-for="(row, i) in members.filter(row => row.joined && 
                                    !(row.is_leader) && row.user_nickname)"
                                    :key="i"
                                    :value="row.user_account_id"
                                >
                                {{ row.user_nickname }}さん
                                </option>
                                <option
                                    v-for="(row, i) in members.filter(row => row.joined && 
                                    !(row.is_leader) && !row.user_nickname)"
                                    :key="i"
                                    :value="row.user_account_id"
                                >
                                チームメンバー[{{row.user_account_id}}]さん
                                </option>
                            </select>
                        </div>
                  </div>
                  <div class="form-ctrl" v-if="state.errorValidate">
                    <div class="form-group">
                      <ErrorMessage v-if="checkErrorMessage(errors)" :message="state.errorValidate" />
                      <ErrorMessage v-if="state.errorInviteMemberMessage" :message="state.errorInviteMemberMessage" />
                    </div>
                  </div>
                  <div class="team_info__edit__container-button">
                    <button
                      @click="$emit('close')"
                      type="button"
                      class="btn-light-gray btn-md"
                    >
                      キャンセル
                    </button>
                     <button
                      class="btn-blue btn-md shadow"
                      :disabled="isSubmitting"
                      :class="{ submitting: isSubmitting }"
                      type="submit"
                    >
                    変更依頼を送る
                     </button>
                  </div>
                </form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/change-leader/ChangeLeader.ts" />
<style lang="scss" scoped src="@/presentation/components/modals/change-leader/ChangeLeader.scss" />
