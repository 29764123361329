import LanguageUtil from "@/commons/LanguageUtil";
import { useStore } from "vuex";
import { defineComponent, onMounted, reactive, } from "vue";
import DIContainer from "@/core/DIContainer";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
export default defineComponent({
    name: "PopupCroppie",
    props: [
        "type",
        "id",
        "viewport",
        "boundary",
        "image",
        "typeFile",
        "uploadImageNotes"
    ],
    components: {
        ErrorMessage: ErrorMessage
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            loading: false,
            message: ""
        });
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var croppie;
        function createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            reader.onload = function (e) {
                if (!e.target) {
                    return;
                }
                return croppie.bind({
                    url: e.target.result
                });
            };
            return reader.readAsDataURL(file);
        }
        function onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }
            return createImage(files[0]);
        }
        function exportImage() {
            return croppie.result('base64').then(function (base64) {
                state.loading = true;
                if (props.typeFile == "base64") {
                    state.loading = false;
                    emit("changeImage", base64);
                    emit("close");
                }
                else {
                    masterInteractor.uploadFile(props.type, props.id, base64).then(function (result) {
                        state.loading = false;
                        if (!result.data) {
                            return;
                        }
                        emit("changeImage", result.data);
                        emit("close");
                    }).catch(function (error) {
                        state.loading = false;
                        if (error.message) {
                            state.message = error.message;
                        }
                    });
                }
                if (croppie) {
                    croppie.destroy();
                }
            });
        }
        function rotateLeft() {
            return croppie.rotate(90);
        }
        function rotateRight() {
            return croppie.rotate(-90);
        }
        function close() {
            if (croppie) {
                croppie.destroy();
            }
            emit("close");
        }
        onMounted(function () {
            var _a, _b;
            if (croppie) {
                croppie.destroy();
            }
            croppie = new Croppie(document.getElementById("croppie"), {
                viewport: (_a = props.viewport) !== null && _a !== void 0 ? _a : { width: 200, height: 200 },
                boundary: (_b = props.boundary) !== null && _b !== void 0 ? _b : { width: 300, height: 300 },
                showZoomer: true,
                enableResize: false,
                enableOrientation: true,
                mouseWheelZoom: 'ctrl'
            });
            if (props.image) {
                croppie.bind({
                    url: props.image
                });
            }
        });
        return {
            state: state,
            store: store,
            props: props,
            onFileChange: onFileChange,
            exportImage: exportImage,
            rotateLeft: rotateLeft,
            rotateRight: rotateRight,
            close: close
        };
    }
});
