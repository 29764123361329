import { defineComponent } from "vue";
import ConfirmModal from "@/presentation/components/modals/confirm/Confirm.vue";
export default defineComponent({
    name: "SlackAlertPopup",
    components: {
        ConfirmModal: ConfirmModal,
    },
    props: [
        "slackResult",
        "slackCreateChannelName",
        "slackInOperation"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var closeSlackAlert = function () {
            emit('close');
        };
        return {
            closeSlackAlert: closeSlackAlert,
        };
    }
});
