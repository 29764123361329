import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive, watchEffect } from "vue";
import { useRouter } from "vue-router";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import DIContainer from "@/core/DIContainer";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
export default defineComponent({
    name: "JoinTeamPopup",
    props: ["title", "content", "loading", "type", "okButtonText"],
    components: { ErrorMessage: ErrorMessage },
    setup: function (props, _a) {
        var emit = _a.emit;
        var router = useRouter();
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            title: props.title,
            loading: false,
            rejected: props.type == "accept" ? true : false
        });
        function close() {
            emit("close");
        }
        function accept() {
            emit("accept");
        }
        watchEffect(function () {
            state.loading = props.loading;
        });
        return {
            state: state,
            props: props,
            close: close,
            accept: accept
        };
    }
});
