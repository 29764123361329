<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="popup_croppie">
                <div id="croppie"></div>
                <ErrorMessage v-if="state.message" :message="state.message"/>
                <div class="btn_group">
                  <label for="upload-image" class="btn-xs btn-blue shadow">
                    Choose image
                  </label>
                  <input
                    type="file"
                    id="upload-image"
                    @error="imageError"
                    @change="onFileChange"
                    accept="image/*"
                  />
                  <button @click="exportImage" class="btn-xs btn-blue shadow">
                    <i
                      v-if="state.loading"
                      class="fas fa-circle-notch fa-spin"
                    ></i
                    >OK
                  </button>
                  <button @click="rotateLeft" class="btn-xs btn-blue shadow">
                    <i class="fas fa-undo"></i>
                  </button>
                  <button @click="rotateRight" class="btn-xs btn-blue shadow">
                    <i class="fas fa-redo"></i>
                  </button>
                  <button @click="close" class="btn-xs btn-light-gray shadow">
                    Close
                  </button>
                </div>
                <div v-if="uploadImageNotes && uploadImageNotes.length" class="upload_image_notes">
                  <ul>
                    <li v-for="note in uploadImageNotes">{{note}}</li>
                  </ul>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" src="@/presentation/components/popup-croppie/PopupCroppie.ts">
</script>

<style lang="scss" src="@/presentation/components/popup-croppie/PopupCroppie.scss" scoped>
</style>