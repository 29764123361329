<template>
  <div class="team_detail_content">
    <!-- TOP -->
    <div class="team_detail_content__col_label">
      <div
        class="team_detail_content__col_label__image"
      >
        <p v-if="!source.image">Team logo</p>
        <img
          v-if="source.image"
          :src="source.image"
          :alt="source.name"
        />
        <button class="shadow" v-if="isEditable && !isDisabledTeams" @click="onEditProfileImage">
          写真を変更
        </button>
      </div>
    </div>
    <div class="team_detail_content__col_info">
      <div class="box-name">
        <p class="name">
          <span>チーム名称</span>
          {{ source.name }}
        </p>
        <p class="name">
          <span>（チーム名）カナ</span>
          {{ source.name_kana }}
        </p>
      </div>
      <div
        class="box-button"
        v-if="isEditable && !isDisabledTeams"
      >
        <a class="edit-button" @click="openEditPopup('name')"
          ><i class="far fa-edit"></i
        ></a>
        <a class="edit-button" @click="openEditPopup('name_kana')"
          ><i class="far fa-edit"></i
        ></a>
      </div>
    </div>
    <!-- メンバー一覧 -->
    <div class="team_detail_content__col_label">
      <label >参加メンバー </label>
    </div>
    <div
      class="team_detail_content__col_info wrap"
    >
      <table v-if="source.members">
        <tr v-for="member of source.members" :key="member">
          <td class="user-name">
            <a @click="gotoUserInfo(member.user_account_id)"
              >{{ member.user_nickname }}</a
            > さん
            <span class="black" v-if="member.is_leader">/ リーダー</span>
            <button
              class="leave btn-next-light-blue btn-xxs shadow"
              v-if="
                isEditable &&
                !isDisabledTeams &&
                member.is_leader != 1 &&
                member.joined
              "
              @click="removeTeamMember(member)"
            >
              脱退させる
            </button>
            <button class="invited btn-xxs" v-if="!isInvited && !member.joined">招待中</button>
          </td>
          <td class="user-description">
            <span
              v-for="(occupation, i) of member.occupations"
              :key="i"
            >
              {{ occupation.name }}
              {{ i < member.occupations.length - 1 ? '、' : '' }}
            </span>
          </td>
        </tr>
      </table>
      <div
        class="invite_member"
        v-if="isEditable && !isDisabledTeams"
      >
        <button
          class="btn-next-light-blue btn-xs shadow btn-invite"
          @click="onInviteMember"
        >
          メンバーを招待
        </button>
      </div>
    </div>
    <!-- メンバーの居住地 -->
    <div class="team_detail_content__col_label">
      <label >メンバーの居住地</label>
    </div>
    <div class="team_detail_content__col_info">
      <p class="pr">
        <span 
          v-for="(row, i) of computedMemberPrefecture"
          :key="i"
        > 
            <span v-if = row.user_nickname
            >
              {{ row.user_nickname }}さん：
              {{ row.prefecture.name }}
              {{ i < computedMemberPrefecture.length - 1 ? '、' : '' }}
            </span>
             <span v-else>
              チームメンバー：
              {{ row.prefecture.name }}
              {{ i < computedMemberPrefecture.length - 1 ? '、' : '' }}
            </span>
        </span>
        <template v-if="computedMemberPrefecture.length">
          <br/>
        </template>
        <template v-else>
          <pre class="pr is-empty">情報がありません</pre>
        </template>
        <span class="caption">
         ※居住地を公開している参加者の居住地が自動的に表示されます。
        </span>
      </p>
    </div>
    <div class="team_detail_content__col_label">
      <label >メンバーのスキル</label>
    </div>
    <div class="team_detail_content__col_info">
      <p>
        下に参加メンバーのスキルが表示されます。<br />
        <span class="caption">
          ※参加者全員のスキルをまとめたものが自動的に表示されます。
        </span>
      </p>
    </div>
    <template v-if="!categories.length">
      <div class="team_detail_content__col_label"><!-- 0件時表示用の空要素--></div>
      <div class="team_detail_content__col_info">
        <pre class="pr is-empty">情報がありません</pre>
      </div>
    </template>
    <!-- メンバーのスキル -->
    <div class="team_detail_content__skill">
      <div
        class="skills"
        v-for="category of categories"
        :key="category.category_id"
      >
        <div
          class="team_detail_content__col_label mr-14"
        >
          <label >{{ category.category_name }}</label>
        </div>
         <!-- 経験・知見-->
        <div
          v-if ="category.category_name == '業態'|| category.category_name  == 'デジタルスキル' || category.category_name  == 'ビジネススキル'"
          class="team_detail_content__col_info mr-14 experience"
        >
          <div 
            class = "skill_experience"
            v-if="category.skills.filter(x => x.is_selected == 1).length > 0"
          >
            <label>実務経験あり</label>
            <div class="box-skill-tag">
              <div
                class="skill"
                v-if="category.skills.filter(x => x.is_selected == 1).length > 0"
                v-for="skill of category.skills.filter(x => x.is_selected == 1)"
                :key="skill.skill_id"
              >
                  {{ skill.skill_name || "" }}
              </div>
            </div>
          </div>
          <div 
            class = "skill_experience"
            v-if="category.skills.filter(x => x.is_selected == 2).length > 0"
          >
            <label>知見あり</label>
            <div class="box-skill-tag">
              <div
                class="skill"
                v-if="category.skills.filter(x => x.is_selected == 2).length > 0"
                v-for="skill of category.skills.filter(x => x.is_selected == 2)"
                :key="skill.skill_id"
              >
                  {{ skill.skill_name || "" }}
              </div>
            </div>
          </div>
        </div>
        <!--スキル-->
        <div
          v-else
          class="team_detail_content__col_info mr-14"
        >
          <div class="box-skill-tag">
            <div
              class="skill"
              v-for="skill of category.skills"
              :key="skill.skill_id"
            >
              {{ skill.skill_name || "" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- slack チャンネル -->
    <div v-if="isEditable && !isDisabledTeams" class="team_detail_content__col_label">
      <label>Slackチャンネル</label>
    </div>
    <div v-if="isEditable && !isDisabledTeams" class="team_detail_content__col_info wrap">
      <div class="create-slack-ch">
        <p class="text-alert message">
          チームメンバー全員がチームに参加したら、以下のボタンを押下してください。
          <br/>
          Slack上にチーム用のプライベートチャンネルが生成されます。
          <br/>
          ※チャンネル作成には、少し時間がかかりますことをご了承ください。
        </p>
        <button
            class="btn-next-light-blue btn-xs shadow create-btn"
            :class="{disabled: source.slack_creatable}"
            :disabled="source.slack_creatable"
            @click="onCreateSlackChannel"
        >
          チャンネルを作成
        </button>
      </div>
    </div>
    <!--チームリーダー変更-->
    <div v-if="isEditable && !isDisabledTeams" class="team_detail_content__col_label">
      <label>リーダー変更</label>
    </div>
    <div v-if="isEditable && !isDisabledTeams" class="team_detail_content__col_info wrap">
      <div v-if = "source.team_leader_change_requests" class="create-slack-ch">
        <p class="text-alert message">
          {{source.members.filter((row) => row.user_account_id === 
          source.team_leader_change_requests.account_id)[0]['user_nickname']}}
          さんに変更依頼を提出済み
          <br/>
          有効期限：{{source.team_leader_change_requests.request_token_expired_at}}
        </p>
        <button
            class="btn-next-light-blue btn-xs shadow create-btn btn-many"
            @click="onChangeLeader"
        >
          再依頼する
        </button>
        <button
            class="btn-next-light-blue btn-xs shadow create-btn btn-many"
            @click="onCancelChangeLeader"
        >
          変更を取り消す
        </button>
      </div>
      <div v-else class="create-slack-ch">
        <button
            class="btn-next-light-blue btn-xs shadow create-btn"
            @click="onChangeLeader"
        >
          リーダーを変更する
        </button>
      </div>
    </div>
    <!-- Others -->
    <div class="team_detail_content__col_label">
      <label >PRポイント</label>
    </div>
    <div class="team_detail_content__col_info">
      <pre class="pr" v-if="source.description">{{ source.description }}</pre>
      <pre class="pr is-empty" v-else>情報がありません</pre>
      <a
        class="edit-button"
        @click="openEditPopup('description')"
        v-if="isEditable && !isDisabledTeams"
        ><i class="far fa-edit"></i
      ></a>
    </div>
    <!-- 対応可能時間帯 -->
    <div class="team_detail_content__col_label">
      <label >対応可能な時間
        <span class="red" v-if="!isOrganization">(必須)</span>
      </label>
    </div>
    <div class="team_detail_content__col_info">
      <p class="pr" v-if="source.available_datetimes && source.available_datetimes.length">
        <span
          v-for="(row, i) of source.available_datetimes"
          :key="i"
        >
          {{ row.name }}
          {{ i < source.available_datetimes.length - 1 ? '、' : '' }}
        </span>
      </p>
      <pre class="pr is-empty" v-else>情報がありません</pre>
      <a
        class="edit-button"
        @click="openEditPopup('available_datetimes')"
        v-if="isEditable && !isDisabledTeams"
        ><i class="far fa-edit"></i
      ></a>
    </div>
    <!-- 希望プロジェクト -->
    <div class="team_detail_content__col_label">
      <label>
        {{ isOrganization ? '希望理由' : '希望プロジェクト' }}
        <span class="red" v-if="!isOrganization">(必須)</span>
      </label>
      <label v-if="!isOrganization" style="font-size: 12px; font-weight:bold">
        ※プロジェクトへの希望を提出した後に、希望理由をご記入いただけます
      </label>
    </div>
    <div class="team_detail_content__col_info">
      <table v-if="!isOrganization && source.selected_project_request && source.selected_project_request.length > 0" class="custom-table" style="width: 97%; margin-right: 3%;">
        <tr>
          <td>希望順位</td>
          <td>プロジェクト名</td>
          <td>希望理由</td>
        </tr>
        <tr v-for="(project,index) in source.selected_project_request">
          <td  class="project-info" v-if="source.selected_project_request">
              第{{ index+1 }}希望
          </td>
          <td class="column-ellipsis">
            {{ project.name }}
          </td>
          <td class="column-ellipsis">
          <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block; max-width: 100%;">
            {{ project.reason }}
          </span><br/>
          <button v-if="isEditable && !isDisabledTeams" type="submit" class="btn-blue reason-btn-md"  @click="openEditPopup('request_reason'+index)" style="float: right;">
            編集
          </button>
          </td>
        </tr>
      </table>
      <table v-else-if="isOrganization && filteredListRequest.length > 0 && source.selected_project_request && source.selected_project_request.length > 0">
        <tr>
          <td>プロジェクト名</td>
          <td>希望理由</td>
        </tr>
        <tr v-for="(project,index) in filteredListRequest">
          <td class="column-ellipsis">
            {{ project.name }}
          </td>
          <td class="column-ellipsis">
          <span>
            {{ project.reason }}
          </span><br/>
          <button v-if="isEditable" type="submit" class="btn-blue reason-btn-md"  @click="openEditPopup('request_reason'+index)" style="float: right;">
            編集
          </button>
          </td>
        </tr>
      </table>
      <pre class="pr is-empty" v-else>希望がありません</pre>
      <a
        class="edit-button"
        @click="openEditPopup('request_project')"
        v-if="isEditable && !isDisabledTeams"
      >
        <i class="far fa-edit"></i>
      </a>
    </div>
  </div>
</template>
<script lang="ts" src="./TeamDetailContent.ts" scoped>
</script>

<style lang="scss" src="./TeamDetailContent.scss" scoped>
</style>

