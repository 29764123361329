<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="join_team_popup__container">
                <div class="join_team_popup__container-title">
                  {{ state.title }}
                </div>
                <div
                  class="join_team_popup__container-content"
                  v-html="content"
                />
                <div class="row-error">
                  <ErrorMessage
                    v-if="state.errorMessage"
                    :message="state.errorMessage"
                  />
                </div>
                <div class="btn_group">
                  <button
                    class="btn-md btn-light-gray shadow"
                    @click="close"
                  >
                    キャンセル
                  </button>
                  <button
                    class="btn-md btn-blue shadow"
                    @click="accept"
                    :disabled="state.loading"
                  >
                    <i
                      v-if="state.loading"
                      class="fas fa-circle-notch fa-spin"
                    ></i>
                    {{ props.okButtonText || 'OK' }}
                  </button>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/reject-team-popup/RejectTeamPopup.ts" />
<style lang="scss" scoped src="@/presentation/components/modals/reject-team-popup/RejectTeamPopup.scss" />
