<template>
  <div class="team_detail_preload">
    <h3 class="preloader"></h3>
    <div class="team_detail_preload__form">
      <div class="team_detail_preload__form__col_label">
        <div class="team_detail_preload__form__col_label__image"></div>
      </div>
      <div class="team_detail_preload__form__col_info">
        <p class="name preloader"></p>
        <p class="name preloader"></p>
      </div>
      <div class="team_detail_preload__form__col_label">
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/team-detail-preloader/TeamDetailPreloader.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/team-detail-preloader/TeamDetailPreloader.scss" scoped>
</style>
