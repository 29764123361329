<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="team_info__edit__container">
                <div class="team_info__edit__container-title">編集中</div>
                <form class="horizontal" @submit="inviteMember">
                  <!-- mail -->
                  <div class="form-ctrl">
                    <div class="form-group">
                      <Input
                        maxlength="255"
                        id="mail"
                        type="text"
                        name="mail"
                        v-model="values.email"
                        placeholder="追加するメンバーの登録メールアドレスを入力"
                      />
                      <div class="box-dropdown" v-if="state.showDropdown">
                        <a>user 候補 { username } さん</a>
                      </div>
                    </div>
                    <button
                      class="btn btn-outline-blue"
                      :disabled="isSubmitting"
                      :class="{ submitting: isSubmitting }"
                      type="submit"
                    >
                      <i
                        v-if="isSubmitting"
                        class="fas fa-circle-notch fa-spin"
                      ></i
                      >招待する
                    </button>
                  </div>
                  <div class="form-ctrl" v-if="state.errorInviteMemberMessage || errors.email">
                    <div class="form-group">
                      <ErrorMessage v-if="checkErrorMessage(errors)" :message="errors.email" />
                      <ErrorMessage v-if="state.errorInviteMemberMessage" :message="state.errorInviteMemberMessage" />
                    </div>
                  </div>
                  <div class="team_info__edit__container-button">
                    <button
                      @click="$emit('close')"
                      type="button"
                      class="btn-light-gray btn-md"
                    >
                      キャンセル
                    </button>
                  </div>
                </form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/invite-member/InviteMember.ts" />
<style lang="scss" scoped src="@/presentation/components/modals/invite-member/InviteMember.scss" />
