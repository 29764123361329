<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="confirm__container">
                <div class="confirm__container-title">{{ props.title }}</div>
                <p v-if="props.description" class="confirm__container-description">{{ props.description }}</p>
                <slot name="content"/>
                <div class="confirm__container-button">
                  <button
                      @click="$emit('canceled')"
                      type="button"
                      class="btn-light-gray btn-md shadow"
                  >
                    {{ props.cancelText }}
                  </button>
                  <button
                      :disabled="props.isSubmitting"
                      :class="{ submitting: props.isSubmitting }"
                      type="button"
                      @click="$emit('confirmed')"
                      class="btn-blue btn-md shadow"
                  >
                    <i v-if="props.isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                    {{ props.okText }}
                  </button>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" src="./Confirm.ts"/>
<style lang="scss" src="./Confirm.scss" scoped></style>
