<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="team_info__edit__container">
                <div class="team_info__edit__container-title">リーダー変更取り消し</div>
                <p class="change_leader_requested">{{info.members.filter((row) => row.user_account_id === 
                    info.team_leader_change_requests.account_id)[0]['user_nickname']}}
                    さんへのチームリーダー変更依頼
                </p>
                <form class="horizontal" @submit="changeLeaderRequest">
                  <div class="team_info__edit__container-button">
                    <button
                      @click="$emit('close')"
                      type="button"
                      class="btn-light-gray btn-md"
                    >
                      戻る
                    </button>
                     <button
                      class="btn-blue btn-md shadow"
                      :disabled="isSubmitting"
                      :class="{ submitting: isSubmitting }"
                      type="submit"
                    >
                    変更依頼を取り消す
                     </button>
                  </div>
                </form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/cancel-change-leader/CancelChangeLeader.ts" />
<style lang="scss" scoped src="@/presentation/components/modals/cancel-change-leader/CancelChangeLeader.scss" />
