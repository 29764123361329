import { defineComponent } from "vue";
import SkillTagPreloader from "@/presentation/components/preloader/skill-tag-preloader/SkillTagPreloader.vue";
export default defineComponent({
    name: "TeamDetailPreload",
    components: { SkillTagPreloader: SkillTagPreloader },
    props: {
        hasBackLink: {
            type: Boolean,
            default: true,
        }
    }
});
