<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="team_info__edit__container">
                <div class="team_info__edit__container-title">編集中</div>
                <div class="instruction-container"  v-if="state.field == 'request_project'">
                  <ol>
                    <li>プロジェクト一覧から希望するプロジェクトにチェックを入れ、画面中央の「選択」ボタンをクリックください。</li>
                    <li>プロジェクト選択後、ドラッグ＆ドロップでプロジェクトを希望する順に並べ替えください。</li>
                    <li>希望するプロジェクトを削除したい場合は<i class="fas fa-trash-alt" style=""></i>をクリックください</li>
                    <li>「保存する」ボタンを押すと希望順位が確定します。</li>
                    <li>希望順位を確定した後、各プロジェクトに希望理由を入力することができます。</li>
                  </ol>
                </div>
                <form class="horizontal" @submit="onSubmit">
                  <!-- name -->
                  <div class="form-ctrl" v-if="state.field == 'name'">
                    <label for="name" class="text"
                      >チーム名<span>（必須）</span></label
                    >
                    <div class="form-group">
                      <Input
                        maxlength="50"
                        id="name"
                        type="text"
                        name="name"
                        v-model="values.name"
                        placeholder="チーム名を入力してください"
                      />
                      <ErrorMessage v-if="errors.name" :message="errors.name" />
                      <template v-if="state.errorValidate.name">
                        <ErrorMessage
                          v-for="message of state.errorValidate.name"
                          :message="message"
                          :key="message"
                        />
                      </template>
                    </div>
                  </div>
                  <!-- name_kana -->
                  <div class="form-ctrl" v-if="state.field == 'name_kana'">
                    <label for="name_kana" class="text"
                      >（チーム名）カナ<span>（必須）</span></label
                    >
                    <div class="form-group">
                      <Input
                        maxlength="50"
                        id="name_kana"
                        type="text"
                        name="name_kana"
                        v-model="values.name_kana"
                        placeholder="チーム名のカナを入力してください"
                      />
                      <ErrorMessage
                        v-if="errors.name_kana"
                        :message="errors.name_kana"
                      />
                      <template v-if="state.errorValidate.name_kana">
                        <ErrorMessage
                          v-for="message of state.errorValidate.name_kana"
                          :message="message"
                          :key="message"
                        />
                      </template>
                    </div>
                  </div>
                  <!-- description -->
                  <div class="form-ctrl" v-if="state.field == 'description'">
                    <label for="description" class="text height"
                      >PRポイント</label
                    >
                    <div class="form-group">
                      <Textarea
                        maxlength="10000"
                        id="description"
                        name="description"
                        v-model="values.description"
                        placeholder="例①：チームはメンバーのスキルバランスが良く、ビジネス・デジタルの双方の観点での検討が可能です。
例②：AIモデル構築に強みのあるメンバーが揃っており、コンペではXXのような受賞歴もあります。"
                      />
                      <ErrorMessage
                        v-if="errors.description"
                        :message="errors.description"
                      />
                      <template v-if="state.errorValidate.description">
                        <ErrorMessage
                          v-for="message of state.errorValidate.description"
                          :message="message"
                          :key="message"
                        />
                      </template>
                    </div>
                  </div>
                  <!-- 希望フォーム -->
                  <div v-if="state.field == 'request_project'">
                    <div class="project-selection-container">
                      <div class="select-projects">
                        <p style="font-size: 22px; margin-bottom: 10px;">▼プロジェクト一覧</p>
                        <div class="list_applicants__searchtitle">
                      <p>フリーワード検索</p>
                    </div>
                    <div class="form-ctrl">
                      <div class="form-group">
                        <input
                        type="text"
                        v-model="state.keyword"
                        placeholder="フリーワードを入力"
                        @input="onChangeKeyword"
                      />
                        <span style="font-size: 12px; font-weight:bold">
                          ※プロジェクト名・企業名・フェーズ名からご検索ください。
                        </span>
                      </div>
                    </div>
                      <div class="table-container">
                        <div class="table_radius">
                          <table class="project-categories-table__body">
                            <template v-for="(element, index) in filteredListProject" :key="element.id">
                              <tr class="drag-item">
                                <th class="handle">
                                  <td><input type="checkbox" :id="'checkbox' + index" @change="onSelectProject(element.id)" :checked="isSelectAll || selectedProjectIds.includes(element.id)">
                                    <label :for="'checkbox' + index"></label><span style="font-size: 12px; padding-left:5px;">{{element.organization_name}}</span><br>
                                    <div style="padding-left:22px;">
                                      {{element.name}}<br>
                                      <span style="font-size: 12px;">{{element.project_categories}}</span>
                                    </div>
                                  </td>
                                </th>
                              </tr>
                            </template>
                          </table>
                        </div>
                      </div>
                        <button class="select-button" @click="toggleSelectAll" type="button">{{ isSelectAll ? '全非選択' : '全選択' }}</button>
                      </div>
                      <div class="project-selection-button">
                        <p>▶︎</p>
                        <button class="move-button" @click="addProjectsToSelected" type="button">移動</button>
                      </div>
                      <div class="selected-projects">
                        <p style="font-size: 22px;">▼プロジェクト希望順位</p>
                          <div class="table-container">
                            <table class="table_radius">
                              <draggable v-model="state.selectedProjects" class="project-categories-table__body">
                                <template #item="{ element, index }">
                                  <table>
                                    <tr class="drag-item">
                                      <th class="handle">
                                        <td class="flex-container">
                                          <div class="menu-icon">
                                            <menu-icon />
                                          </div>
                                          <a class="delete-icon" draggable="false" @click="removeSelectedProject(element.id)" style="font-size: 12px; color: #808080;"><i class="fas fa-trash-alt" style="margin-left: 2px; font-size: 12px;"></i></a>
                                          <div class="details" style="position: relative;">
                                            <div class="test" style="font-size: 12px;"> 【第{{index+1}}希望】 </div> 
                                            <div class="organization"> 
                                              <span class="organization_name" style="margin-left: 3px; font-size: 8px;">{{element.organization_name}}</span> 
                                            </div> 
                                            <div class="element_name" style="margin-left: 3px;">
                                              {{element.name}}
                                            </div> 
                                            <div class="project_categories" style="margin-left: 3px; font-size: 12px;">
                                              {{element.project_categories}}
                                            </div>
                                          </div>
                                        </td>
                                      </th>
                                    </tr>
                                  </table>
                                </template>
                              </draggable>
                            </table>
                          </div>
                      </div>
                    </div>
                  </div>
                  <!-- available_datetimes -->
                  <div class="form-ctrl" v-if="state.field == 'available_datetimes'">
                    <label for="available_datetimes" class="text height"
                      >対応可能な時間</label
                    >
                    <div class="form-group">
                      <span
                        class="container-radio"
                        v-for="(row,i) in [
                          { id: 1000, name: '平日 19時以降' },
                          { id: 1001, name: '土曜 午前' },
                          { id: 1002, name: '土曜 午後' },
                          { id: 1003, name: '日曜 午前' },
                          { id: 1004, name: '日曜 午後' },
                        ]"
                        :key="i"
                      >
                        <input
                            name="available_datetimes"
                            type="checkbox"
                            :id="`available_datetimes_${i}`"
                            :value="row.id"
                            :checked="detectChecked(row)"
                            @change="setChecked(row)"
                        />
                        <label :for="`available_datetimes_${i}`">
                          {{ row.name }}
                        </label>
                      </span>
                      <ErrorMessage
                        v-if="errors.available_datetimes"
                        :message="errors.available_datetimes"
                      />
                      <template v-if="state.errorValidate.available_datetimes">
                        <ErrorMessage
                          v-for="message of state.errorValidate.available_datetimes"
                          :message="message"
                          :key="message"
                        />
                      </template>
                    </div>
                  </div>
                  <!-- 希望理由 -->
                  <template v-for="n in 1000">
                    <div  v-if="state.field == 'request_reason'+(n-1)">
                      <label :for="'request_reason' + n" class="text request" style="font-size: 20px;">
                      {{'【第'+n+'希望】'}}<br/>{{info.selected_project_request[n-1].organization_name}}<br/>{{info.selected_project_request[n-1].name}}
                      </label>
                      <div class="form-ctrl">
                        <label :for="'request_reason' + n" class="text request">希望理由</label>
                          <div class="form-group"><br/>
                            <textarea
                              :id="'request_reason' + n"
                              :name="'request_reason' + n"
                              v-model="state.tempReasons[n-1]"
                              placeholder="例①：チームには製造業経験者が3人おり、他企業の状況を踏まえた検討が可能です。特に工数がかかっている発注業務の課題把握から取り組み、全社のDXロードマップを作成したいと考えています。
例②：貴社の経営理念であるXXXに共感しており、その理念を第一に据えた検討を行いたいと考えています。"
                            ></textarea>
                          </div>
                      </div>
                    </div>
                  </template>
                  <div
                    class="form-ctrl"
                    v-if="state.errorUpdateTeamInfoMessage"
                  >
                    <label class="text"></label>
                    <div class="form-group">
                      <ErrorMessage
                        :message="state.errorUpdateTeamInfoMessage"
                      />
                    </div>
                  </div>
                  <div class="team_info__edit__container-button">
                    <button
                      @click="$emit('close')"
                      type="button"
                      class="btn-light-gray btn-md"
                    >
                      キャンセル
                    </button>
                    <button
                      :disabled="isSubmitting"
                      :class="{ submitting: isSubmitting }"
                      type="submit"
                      class="btn-blue btn-md"
                      v-if="!state.field.startsWith('request')"
                    >
                      <i
                        v-if="isSubmitting"
                        class="fas fa-circle-notch fa-spin"
                      ></i>
                      編集完了
                    </button>
                    <button
                      :disabled="isSubmitting"
                      :class="{ submitting: isSubmitting }"
                      type="submit"
                      class="btn-blue btn-md"
                      v-if="state.field === 'request_project'"
                      @click="updateSelectedProjectRequest"
                    >
                      <i
                        v-if="isSubmitting"
                        class="fas fa-circle-notch fa-spin"
                      ></i>
                      編集完了
                    </button>
                    <button
                      :disabled="isSubmitting"
                      :class="{ submitting: isSubmitting }"
                      type="submit"
                      class="btn-blue btn-md"
                      v-if="state.field.startsWith('request_reason')"
                      @click="updateSelectedProjectRequestReason"
                    >
                      <i
                        v-if="isSubmitting"
                        class="fas fa-circle-notch fa-spin"
                      ></i>
                      編集完了
                    </button>
                  </div>
                </form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/team-info-edit/TeamInfoEdit.ts" />
<style lang="scss" scoped src="@/presentation/components/modals/team-info-edit/TeamInfoEdit.scss" />
