import { defineComponent } from "vue";
export default defineComponent({
    name: "SkillTagPreloader",
    props: ["number"],
    setup: function (props) {
        return {
            props: props
        };
    }
});
