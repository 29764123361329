import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive, onMounted } from "vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import Input from "@/presentation/components/input/Input.vue";
export default defineComponent({
    name: "TeamInfoEdit",
    components: {
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    props: [
        "isOwner",
        "members",
        "team_id"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            showDropdown: false,
            errorInviteMemberMessage: "",
            errorValidate: "",
            isSubmitting: false,
            team_id: props.team_id
        });
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        var _b = useForm(), values = _b.values, isSubmitting = _b.isSubmitting, setValues = _b.setValues, handleSubmit = _b.handleSubmit, errors = _b.errors;
        var new_leader_account_id = useField("new_leader_account_id", yup.number().nullable()).value;
        var changeLeaderRequest = handleSubmit(function () {
            return teamInteractor.changeLeader(state.team_id, values.new_leader_account_id).then(function (result) {
                emit("close");
                emit("success");
            }).catch(function (error) {
                if (error.validation_errors) {
                    state.errorValidate = error.validation_errors;
                }
                if (error.message) {
                    state.errorInviteMemberMessage = error.message;
                }
            });
        });
        function checkErrorMessage(error) {
            if (error) {
                state.errorValidate = "正しいユーザーを選択してください";
                return true;
            }
            return false;
        }
        onMounted(function () {
            setValues({ new_leader_account_id: "" });
        });
        return {
            state: state,
            isSubmitting: isSubmitting,
            values: values,
            errors: errors,
            onMounted: onMounted,
            changeLeaderRequest: changeLeaderRequest,
            checkErrorMessage: checkErrorMessage
        };
    }
});
