import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive, onMounted } from "vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import * as agh from "agh.sprintf";
import Input from "@/presentation/components/input/Input.vue";
export default defineComponent({
    name: "TeamInfoEdit",
    components: {
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    props: [
        "team_id",
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            showDropdown: false,
            errorInviteMemberMessage: "",
            team_id: props.team_id
        });
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        var _b = useForm(), values = _b.values, isSubmitting = _b.isSubmitting, handleSubmit = _b.handleSubmit, errors = _b.errors;
        var email = useField('email', yup
            .string()
            .required(state.aLang.validation_required)
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))
            .email(state.aLang.validation_format_email)).value;
        var inviteMember = handleSubmit(function () {
            return teamInteractor.inviteMember(state.team_id, values.email).then(function (result) {
                emit("close");
                emit("success");
            }).catch(function (error) {
                if (error.message) {
                    state.errorInviteMemberMessage = error.message;
                }
                if (error.validation_errors && error.validation_errors.mail_address && error.validation_errors.mail_address[0]) {
                    state.errorInviteMemberMessage = error.validation_errors.mail_address[0];
                }
            });
        });
        function checkErrorMessage(error) {
            if (error.email) {
                state.errorInviteMemberMessage = "";
                return true;
            }
            return false;
        }
        onMounted(function () {
        });
        return {
            state: state,
            isSubmitting: isSubmitting,
            values: values,
            errors: errors,
            inviteMember: inviteMember,
            checkErrorMessage: checkErrorMessage
        };
    }
});
