<template>
  <div class="skill_tag_preload">
      <div class="box_preloader" v-for="i in props.number" :key="i"></div>
  </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/skill-tag-preloader/SkillTagPreloader.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/skill-tag-preloader/SkillTagPreloader.scss" scoped>
</style>