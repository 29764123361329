import { defineComponent, computed, onMounted, reactive, watch } from "vue";
import Star from "@/presentation/components/star-profile/StarProfile.vue";
import { useStore } from "vuex";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import DIContainer from "@/core/DIContainer";
import { getCurrentOrganizationId } from "@/commons/UserStatusUtis";
export default defineComponent({
    name: "TeamDetailContents",
    components: {
        Star: Star
    },
    props: [
        "isOwner",
        "isInvited",
        "source",
        "categories",
        "openEditPopup",
        "onEditProfileImage",
        "gotoUserInfo",
        "removeTeamMember",
        "gotoUserAchievement",
        "onInviteMember",
        "onChangeLeader",
        "onCancelChangeLeader",
        "onCreateSlackChannel",
    ],
    setup: function (props) {
        var store = useStore();
        var isOrganization = computed(function () { return store.state.headerMode === 'organizationLogged'; });
        var isEditable = computed(function () { return (store.state.headerMode !== 'organizationLogged' && props.isOwner && !props.isInvited); });
        var state = reactive({
            isDisabledTeams: false,
        });
        var isDisabledTeams = computed(function () { return store.state.isDisabledTeams; });
        watch(function () { return store.state.isDisabledTeams; }, function (newVal) {
            state.isDisabledTeams = newVal;
        }, {
            immediate: true
        });
        var OrganizationId = getCurrentOrganizationId();
        var filteredListRequest = computed(function () {
            try {
                var projectRequests = typeof props.source.selected_project_request === "string"
                    ? JSON.parse(props.source.selected_project_request)
                    : props.source.selected_project_request;
                if (Array.isArray(projectRequests)) {
                    return projectRequests.filter(function (row) { return row.organization_id === OrganizationId; });
                }
            }
            catch (error) {
                console.error("Error parsing selected_project_request:", error);
            }
            return [];
        });
        var computedMemberPrefecture = computed(function () {
            return props.source.members.filter(function (row) { return row.is_public_prefecture === 1; });
        });
        onMounted(function () {
            var masterInteractor = DIContainer.instance.get(MasterInteractor);
            if (!store.state.prefectures) {
                masterInteractor
                    .getAllPerfecture()
                    .then(function (result) {
                    if (!result.data)
                        return;
                    store.commit("setPrefectures", result.data);
                })
                    .catch(function (error) { });
            }
            props.source.selected_project_request = JSON.parse(props.source.selected_project_request);
            store.dispatch("checkActiveTeamInput");
        });
        return {
            isOrganization: isOrganization,
            isEditable: isEditable,
            computedMemberPrefecture: computedMemberPrefecture,
            isDisabledTeams: isDisabledTeams,
            OrganizationId: OrganizationId,
            filteredListRequest: filteredListRequest
        };
    }
});
