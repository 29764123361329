<template>
  <ConfirmModal
      title="チームチャンネルの作成"
      :isSubmitting="isSubmitting"
      @canceled="$emit('canceled')"
      @confirmed="$emit('confirmed')"
  >
    <template v-slot:content>
      <div class="create-slack-channel-content">
        <p>
          「招待したメンバー」が全員チームに参加したら、「決定ボタン」を押してチャンネルを作成してください。
        </p>
        <p class="alert">
          ※未参加のメンバーは、チャンネルに招待されません。
          <br/>
          一度「決定ボタン」を押してしまうと、キャンセル・再作成は出来ませんので、必ず全員が参加してから「決定ボタン」を押してください。
        </p>
      </div>
    </template>
  </ConfirmModal>
</template>
<script lang="ts" src="./CreateSlackChannel.ts" />
<style lang="scss" scoped src="./CreateSlackChannel.scss" />
