var Team = /** @class */ (function () {
    function Team() {
        this.team_id = null;
        this.name = null;
        this.name_kana = null;
        this.image = null;
        this.description = null;
    }
    return Team;
}());
export default Team;
