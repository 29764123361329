import { defineComponent, onMounted } from "vue";
import ConfirmModal from "@/presentation/components/modals/confirm/Confirm.vue";
export default defineComponent({
    name: "CreateSlackChannel",
    components: {
        ConfirmModal: ConfirmModal,
    },
    props: {
        onOk: Function,
        isSubmitting: Boolean,
    },
    emits: ["confirmed", "canceled"],
    setup: function () {
        onMounted(function () { });
        return {};
    }
});
